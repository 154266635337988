import React, { useEffect, useMemo } from 'react';
import { GetServerSideProps, Redirect } from 'next';
import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
// import { useFormat } from 'helpers/hooks/useFormat';
import { createClient, ResponseError, LocaleStorage, useDarkMode } from 'frontastic';
import { FrontasticRenderer } from 'frontastic/lib/renderer';
import { tastics } from 'frontastic/tastics';
import { Log } from '../helpers/errorLogger';
import getCanonical from '../helpers/getCanonical';
import { useFormat } from '../helpers/hooks/useFormat';
import CableCut from '../public/images/cable-cut.png';
// import styles from './slug.module.css';

type SlugProps = {
  // This needs an overhaul. Can be too many things in my opinion (*Marcel)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  // data: RedirectResponse | PageDataResponse | ResponseError | { ok: string; message: string } | string;
  locale: string;
};

export default function Slug({ data, locale }: SlugProps) {
  LocaleStorage.locale = locale;
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });

  const router = useRouter();
  const canonicalUrl = getCanonical(router.asPath);

  const { applyTheme } = useDarkMode();

  useEffect(() => {
    applyTheme(data?.pageFolder?.configuration?.theme);
  }, [applyTheme, data?.pageFolder?.configuration]);

  {
    /* TODO: check meta data for all types of dynamic pages */
  }
  const dynamicData: { [key: string]: string } | null = useMemo(() => {
    const dataSourceConfiguration = data?.pageFolder?.dataSourceConfigurations
      ?.filter((config) => config.type === 'snaq/category')
      .shift();
    const dataStreamId: string | null = dataSourceConfiguration?.streamId || null;
    return dataStreamId && data?.data?.dataSources[dataStreamId] ? data.data.dataSources[dataStreamId] : null;
  }, [data?.pageFolder?.dataSourceConfigurations, data?.data?.dataSources]);

  if (!data || typeof data === 'string' || (!data.ok && data.message)) {
    return (
      <div className="relative h-screen p-6">
        <div className="absolute top-1/2 left-1/2 flex w-full -translate-x-1/2 -translate-y-1/2 flex-col items-center p-6 text-center">
          <Image src={CableCut} loader={(options) => options.src} alt="Cable cut" />
          <div className="mt-5 text-center">
            <h1 className="text-3xl font-bold">
              {formatMessage({ id: 'page.notFound', defaultMessage: 'Page not found' })}
            </h1>
            <p className="mt-3">{data.message ? data.message : data}</p>
            <Link href="/">
              <a className="mt-1 block text-blue-500 underline transition-colors duration-150 ease-out hover:text-blue-400">
                {formatCheckoutMessage({ id: 'continueShopping', defaultMessage: 'Continue shopping' })}
              </a>
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Head>
        {dynamicData?.metaTitle ? (
          <title>{dynamicData.metaTitle}</title>
        ) : data?.pageFolder?.configuration?.seoTitle ? (
          <title>{data.pageFolder.configuration.seoTitle}</title>
        ) : null}
        {dynamicData?.metaDescription ? (
          <meta name="description" content={dynamicData.metaDescription} />
        ) : data?.pageFolder?.configuration?.seoDescription ? (
          <meta name="description" content={data.pageFolder.configuration.seoDescription} />
        ) : null}
        {/*<meta
          name="description"
          content={formatMessage({ id: 'meta.desc', defaultMessage: 'Find largest shopping collections here!' })}
        />*/}
        {data?.pageFolder?.configuration?.metaRobots && data.pageFolder.configuration.metaRobots !== '' && (
          <>
            <meta name="robots" content={data.pageFolder.configuration.metaRobots} />
            {!data.pageFolder.configuration.metaRobots.includes('noindex') && (
              <link rel="canonical" href={canonicalUrl} />
            )}
          </>
        )}
      </Head>
      <FrontasticRenderer data={data} tastics={tastics} />
    </>
  );
}

export const getServerSideProps: GetServerSideProps | Redirect = async ({ params, locale, query, req, res }) => {
  LocaleStorage.locale = locale;

  const frontastic = createClient();
  const data = await frontastic.getRouteData(params, locale, query, req, res);

  if (data) {
    if (data instanceof ResponseError && data.getStatus() == 404) {
      return {
        notFound: true,
      };
    } else if (typeof data === 'object' && 'target' in data) {
      return {
        redirect: {
          destination: data.target,
          statusCode: data.statusCode,
        } as Redirect,
      };
    }

    /* NOTE: Apparently not having any effect... should be removed if unfixable
    if (typeof data === 'object' && 'pageFolder' in data && data.pageFolder.configuration?.cacheControlResponseHeader) {
      res.setHeader('cache-control', data.pageFolder.configuration?.cacheControlResponseHeaderValue || '');
    }
    */
  }

  if (data instanceof Error) {
    // @TODO: Render nicer error page in debug mode, which shows the error to
    // the developer and also outlines how to debug this (take a look at
    // frontastic-CLI).
    Log.error('Error retrieving data: ', data);
    return {
      notFound: true,
    };
  }

  if (typeof data === 'string') {
    return {
      props: {
        data: { error: data },
        error: data,
      },
    };
  }

  if ((data as any)!.message === 'Could not resolve page from path') {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      data: data || null,
      locale: locale,
      ...(await serverSideTranslations(locale, [
        'common',
        'cart',
        'product',
        'checkout',
        'account',
        'error',
        'success',
        'wishlist',
        'newsletter',
        'contactform',
        'comparison',
      ])),
    },
  };
};
